import React from 'react';
import { useSelector } from 'react-redux';
// Utils
import { formatThousandSeparator } from '../../utils/formatThousandSeparator';
// Style
import './SummarySearch.css';

const SummarySearch = ({ total = 0 }) => {
  const { queryFetched } = useSelector((state) => state.library);
  if (queryFetched) {
    return (
      <div className='summarySearch'>
        <h3 className='summarySearchQuery'>Searched term: {queryFetched}</h3>
        <p className='summarySearchTotal'>
          {formatThousandSeparator(total)} results
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export default SummarySearch;
